import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Link } from "react-router-dom";
import { onLogin } from "../../store";

function LoginPage() {
  const [userArr, setUserArr] = useState(() => {
    return JSON.parse(localStorage.getItem("userArr")) || [];
  });

  useEffect(() => {
    localStorage.setItem("userArr", JSON.stringify(userArr));
  }, [userArr]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState(false);

  const loginHandler = (e) => {
    e.preventDefault();

    function validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    if (!formData.email || !formData.password) {
      setError("Please full input!");
    } else if (!validateEmail(formData.email)) {
      setError("Please input correctly email");
    } else if (formData.password.length < 8) {
      setError("Wrong Password!");
    } else if (userArr.find((item) => item.email !== formData.email)) {
      setError("User is not existed");
    } else if (
      userArr.find(
        (item) =>
          item.email === formData.email && item.password !== formData.password
      )
    ) {
      setError("Wrong Password");
    } else setError(false);

    if (!error) {
      if (
        userArr.find(
          (item) =>
            item.email === formData.email && item.password === formData.password
        )
      ) {
        setIsLoggedIn(true);
        localStorage.setItem("user_email", formData.email);
        dispatch(onLogin(localStorage.getItem("user_email")));
        setRedirect(true);
      } else setIsLoggedIn(false);
    }
  };

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-50">
          <span className="login100-form-title p-b-33">Sign In</span>

          <div className="d-flex justify-content-center pb-5">
            {/* Display error */}
            {error && <span className="text-danger">* {error} *</span>}
          </div>

          <div className="wrap-input100 validate-input">
            <input
              className="input100"
              type="text"
              placeholder="Email"
              defaultValue=""
              id="email"
              name="email"
              onChange={(e) => onChangeHandler(e)}
            />
          </div>

          <div className="wrap-input100 rs1 validate-input">
            <input
              className="input100"
              type="password"
              placeholder="Password"
              defaultValue=""
              id="password"
              name="password"
              onChange={(e) => onChangeHandler(e)}
            />
          </div>

          <div className="container-login100-form-btn m-t-20">
            {redirect && <Navigate to={`/`} />}
            <button
              className="login100-form-btn"
              onClick={(e) => loginHandler(e)}
            >
              Sign in
            </button>
          </div>

          <div className="text-center p-t-45 p-b-4">
            <span className="txt1">Create an account?</span>
            &nbsp;
            <Link to="/register" className="txt2 hov1">
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
