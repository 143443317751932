import { RouterProvider, createBrowserRouter } from "react-router-dom";

import RootLayout from "./pages/Root";
import HomePage from "./pages/Home/Home";
import DetailPage from "./pages/Detail/Detail";
import ShopPage from "./pages/Shop/Shop";
import CartPage from "./pages/Cart/Cart";
import CheckoutPage from "./pages/Checkout/Checkout";
import LoginPage from "./pages/Auth/Login";
import RegisterPage from "./pages/Auth/Register";
import ErrorPage from "./pages/Error";
import { Provider } from "react-redux";
import { store } from "./store";

import "./App.css";
import "./css/custom.css";
import "./css/style.default.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "/shop",
        element: <ShopPage />,
      },
      {
        path: "/detail/:productId",
        element: <DetailPage />,
      },
      {
        path: "/cart",
        element: <CartPage />,
      },
      {
        path: "/checkout",
        element: <CheckoutPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/register",
        element: <RegisterPage />,
      },
    ],
  },
]);

export default function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <RouterProvider router={router} />;
      </Provider>
    </div>
  );
}
