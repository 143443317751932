import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hidePopup } from '../store';
import convertMoney from '../convertMoney';

export const Popup = () => {
  const product = useSelector((state) => state.popup.product);
  const dispatch = useDispatch();

  if (!product) return null;

  return (
        <div className="modal fade show" id={`product_${product._id.$oid}`} key={product._id.$oid}>
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <div className="row align-items-stretch">
                      <div className="col-lg-6 p-lg-0">
                        <img
                          style={{ width: "100%" }}
                          className="product-view d-block h-100 bg-cover bg-center"
                          src={product.img1}
                          data-lightbox={`product_${product._id.$oid}`}
                          alt={product.name}
                        />
                        <img
                          className="d-none"
                          href={product.img2}
                          alt={product.name}
                        />
                        <img
                          className="d-none"
                          href={product.img3}
                          alt={product.name}
                        />
                      </div>
                      <div className="col-lg-6">
                        
                        <a onClick={() => dispatch(hidePopup())}
                          className="close p-4"
                          type="button"
                          href="#section_product"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          ×
                        </a>
                        <div className="p-5 my-md-4">
                          <h2 className="h4">{product.name}</h2>
                          <b className="text-muted">
                            {convertMoney(product.price)} VND
                          </b>
                          <br></br>
                          <p className="text-small mb-4">{product.short_desc}</p>
                          <div className="row align-items-stretch mb-4">
                            <div className="col-sm-5 pl-sm-0 fix_addwish">
                              <a
                                className="btn btn-dark btn-sm btn-block h-100 d-flex align-items-center justify-content-center px-0"
                                href={`/detail/${product._id.$oid}`}
                                target="_blank"
                              >
                                <i className="fa fa-shopping-cart"></i>
                                <span className="ml-2">View Detail</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  );
};


