import { useState } from "react";
import ProductList from "./ProductList";

function Category(props) {
  const { products } = props;

  const [productsFiltered, setProductsFiltered] = useState("");
  const [search, setSearch] = useState("");

  var category = "all";

  const categoryHandler = (name) => {
    category = name;

    if (category === "all") {
      setProductsFiltered(products);
    } else {
      const newProducts = products.filter((item) => category === item.category);
      console.log(newProducts);
      setProductsFiltered(newProducts);
    }
  };

  return (
    <section className="py-5">
      <div className="container p-0">
        <div className="row">
          {/* Category List in left */}
          <div className="col-lg-3 order-2 order-lg-1">
            <h5 className="text-uppercase mb-4">Categories</h5>
            <div className="py-2 px-4 bg-dark text-white mb-3">
              <strong className="small text-uppercase font-weight-bold">
                Apple
              </strong>
            </div>

            <ul className="list-unstyled small text-muted pl-lg-4 font-weight-normal">
              <li className="mb-2">
                <a
                  className="reset-anchor"
                  href="javascript:;"
                  onClick={() => categoryHandler("all")}
                >
                  All
                </a>
              </li>
            </ul>

            <div className="py-2 px-4 bg-light mb-3">
              <strong className="small text-uppercase font-weight-bold">
                iDevices & Mac
              </strong>
            </div>

            <ul className="list-unstyled small text-muted pl-lg-4 font-weight-normal">
              <li className="mb-2">
                <a
                  className="reset-anchor"
                  href="javascript:;"
                  onClick={() => categoryHandler("iphone")}
                >
                  iPhone
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="reset-anchor"
                  href="javascript:;"
                  onClick={() => categoryHandler("ipad")}
                >
                  iPad
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="reset-anchor"
                  href="javascript:;"
                  onClick={() => categoryHandler("macbook")}
                >
                  MacBook
                </a>
              </li>
            </ul>

            <div className="py-2 px-4 bg-light mb-3">
              <strong className="small text-uppercase font-weight-bold">
                Wireless
              </strong>
            </div>

            <ul className="list-unstyled small text-muted pl-lg-4 font-weight-normal">
              <li className="mb-2">
                <a
                  className="reset-anchor"
                  href="javascript:;"
                  onClick={() => categoryHandler("airpod")}
                >
                  AirPods
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="reset-anchor"
                  href="javascript:;"
                  onClick={() => categoryHandler("watch")}
                >
                  Watch
                </a>
              </li>
            </ul>

            <div className="py-2 px-4 bg-light mb-3">
              <strong className="small text-uppercase font-weight-bold">
                Other
              </strong>
            </div>

            <ul className="list-unstyled small text-muted pl-lg-4 font-weight-normal mb-5">
              <li className="mb-2">
                <a
                  className="reset-anchor"
                  href="javascript:;"
                  onClick={() => categoryHandler("mouse")}
                >
                  Mouse
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="reset-anchor"
                  href="javascript:;"
                  onClick={() => categoryHandler("keyboard")}
                >
                  Keyboard
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="reset-anchor"
                  href="javascript:;"
                  onClick={() => categoryHandler("other")}
                >
                  Other
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-9 order-1 order-lg-2 mb-5 mb-lg-0">
            <div className="row mb-3 align-items-center">
              {/* Search Box */}
              <div className="col-lg-4">
                <input
                  className="form-control form-control-lg"
                  type="text"
                  placeholder="Search something"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
            </div>

            {/* Products List in right */}
            <ProductList products={productsFiltered} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Category;
