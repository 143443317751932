import { Link } from "react-router-dom";
import convertMoney from "../../../convertMoney";

function ProductList(props) {
  const { products } = props;

  return (
    <div className="row">
      {products &&
        products.map((item) => (
          <div
            className="col-lg-4 col-sm-6 Section_Category"
            key={item._id.$oid}
          >
            <div className="product text-center">
              <div className="position-relative mb-3">
                <div className="badge text-white badge-"></div>
                <Link className="d-block" to={`/detail/${item._id.$oid}`}>
                  <img className="img-fluid w-100" src={item.img1} alt="..." />
                </Link>
                <div className="product-overlay">
                  <ul className="mb-0 list-inline"></ul>
                </div>
              </div>
              <h6>
                <a className="reset-anchor" href={`/detail/${item._id.$oid}`}>
                  {item.name}
                </a>
              </h6>
              <p className="small text-muted">{convertMoney(item.price)} VND</p>
            </div>
          </div>
        ))}
    </div>
  );
}

export default ProductList;
