import { useParams } from "react-router-dom";
import useHttp from "../../hooks/use-http";
import { useState, useEffect } from "react";
import convertMoney from "../../convertMoney";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCart } from "../../store";

function DetailPage(props) {
  const { productId } = useParams();
  console.log(productId);
  const [productDetail, setProductDetail] = useState();
  const dispatch = useDispatch();
  const listCart = useSelector((state) => state.cart.listCart);
  const user_email = useSelector((state) => state.cart.user_email);

  const [success, setSuccess] = useState(false);

  // Fetch data from API
  const apiUrl =
    "https://firebasestorage.googleapis.com/v0/b/funix-subtitle.appspot.com/o/Boutique_products.json?alt=media&token=dc67a5ea-e3e0-479e-9eaf-5e01bcd09c74";

  const { data, fetchApi } = useHttp({
    url: apiUrl,
  });

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  console.log(data);

  useEffect(() => {
    setProductDetail(data.find((item) => item._id.$oid === productId));
  }, [data]);
  console.log(productDetail);

  //Change quantity
  const [text, setText] = useState(1);
  const onChangeText = (e) => {
    setText(e.target.value);
  };

  const increase = () => {
    const value = parseInt(text) + 1;

    setText(value);
  };

  const decrease = () => {
    const value = parseInt(text) - 1;
    if (value === 0) return;

    setText(value);
  };

  // Add To Cart function
  const addToCart = () => {
    let user_cart = "";

    if (localStorage.getItem("user_email")) {
      user_cart = localStorage.getItem("user_email");
    } else {
      user_cart = user_email;
    }

    const data = {
      user: user_cart,
      idProduct: productDetail._id.$oid,
      nameProduct: productDetail.name,
      priceProduct: productDetail.price,
      count: text,
      img: productDetail.img1,
    };

    dispatch(addCart(data));
    setSuccess(true);
  };

  return (
    <>
      {productDetail && (
        <section className="py-5">
          <div className="container">
            <div className="row mb-5">
              <div className="col-lg-6">
                <div className="row m-sm-0">
                  <div className="col-sm-2 p-sm-0 order-2 order-sm-1 mt-2 mt-sm-0">
                    <div
                      className="owl-thumbs d-flex flex-row flex-sm-column"
                      data-slider-id="1"
                    >
                      <div className="owl-thumb-item flex-fill mb-2 mr-2 mr-sm-0">
                        <img
                          className="w-100"
                          src={productDetail.img1}
                          alt="..."
                        />
                      </div>
                      <div className="owl-thumb-item flex-fill mb-2 mr-2 mr-sm-0">
                        <img
                          className="w-100"
                          src={productDetail.img2}
                          alt="..."
                        />
                      </div>
                      <div className="owl-thumb-item flex-fill mb-2 mr-2 mr-sm-0">
                        <img
                          className="w-100"
                          src={productDetail.img3}
                          alt="..."
                        />
                      </div>
                      <div className="owl-thumb-item flex-fill mb-2 mr-2 mr-sm-0">
                        <img
                          className="w-100"
                          src={productDetail.img4}
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    id="carouselExampleControls"
                    className="carousel slide col-sm-10 order-1 order-sm-2"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner owl-carousel product-slider">
                      <div className="carousel-item active">
                        <img
                          className="d-block w-100"
                          src={productDetail.img1}
                          alt="First slide"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          className="d-block w-100"
                          src={productDetail.img2}
                          alt="Second slide"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          className="d-block w-100"
                          src={productDetail.img3}
                          alt="Third slide"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          className="d-block w-100"
                          src={productDetail.img4}
                          alt="Third slide"
                        />
                      </div>
                    </div>
                    <a
                      className="carousel-control-prev"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <br></br>
                <h1>{productDetail.name}</h1>
                <br></br>
                <p className="text-muted lead">
                  {convertMoney(productDetail.price)} VND
                </p>
                <br></br>
                <p className="text-small mb-4">{productDetail.short_desc}</p>
                <ul className="list-unstyled small d-inline-block">
                  <li className="mb-3 bg-white text-muted">
                    <strong className="text-uppercase text-dark">
                      Category:
                    </strong>
                    <a className="reset-anchor ml-2">
                      {productDetail.category}
                    </a>
                  </li>
                </ul>

                {/* Change Quantity to Add Cart */}
                <div className="row align-items-stretch mb-4">
                  <div className="col-sm-5 pr-sm-0">
                    <div className="border d-flex align-items-center justify-content-between py-1 px-3 bg-white border-white">
                      <span className="small text-uppercase text-gray mr-4 no-select">
                        Quantity
                      </span>
                      <div className="quantity">
                        <button
                          className="dec-btn p-0"
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className="fas fa-caret-left"
                            onClick={decrease}
                          ></i>
                        </button>
                        <input
                          className="form-control border-0 shadow-0 p-0"
                          type="text"
                          value={text}
                          onChange={onChangeText}
                        />
                        <button
                          className="inc-btn p-0"
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className="fas fa-caret-right"
                            onClick={increase}
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3 pl-sm-0">
                    <button
                      className="btn btn-dark btn-sm btn-block d-flex align-items-center justify-content-center px-0 text-white"
                      onClick={addToCart}
                    >
                      Add to cart
                    </button>
                    {success && (
                      <div class="alert alert-success" role="alert">
                        Success!
                      </div>
                    )}
                  </div>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>

            <br />
            <ul className="nav nav-tabs border-0">
              <li className="nav-item">
                <div
                  className="nav-link fix_comment"
                  style={{ backgroundColor: "#383838", color: "#ffffff" }}
                >
                  Description
                </div>
              </li>
            </ul>

            {/* Description */}
            <div className="tab-content mb-5">
              <div className="tab-pane fade show active">
                <div className="pt-4 pb-4 bg-white">
                  <h6 className="text-uppercase">Product description </h6>
                  <br></br>
                  <p
                    className="text-muted text-small mb-0"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {productDetail.long_desc}
                  </p>
                </div>
              </div>
            </div>

            {/* Related Products */}
            <h2 className="h5 text-uppercase mb-4">Related products</h2>
            <div className="row">
              {data &&
                data
                  .filter(
                    (el) =>
                      el.category === productDetail.category &&
                      el._id.$oid !== productDetail._id.$oid
                  )
                  .map((value) => (
                    <div className="col-lg-3 col-sm-6" key={value._id}>
                      <div className="product text-center skel-loader">
                        <div className="d-block mb-3 position-relative">
                          <img
                            className="img-fluid w-100"
                            src={value.img1}
                            alt="..."
                          />
                          <div className="product-overlay">
                            <ul className="mb-0 list-inline"></ul>
                          </div>
                        </div>
                        <h6>
                          <Link
                            className="reset-anchor"
                            to={`/detail/${value._id}`}
                          >
                            {value.name}
                          </Link>
                        </h6>
                        <p className="small text-muted">
                          {convertMoney(value.price)} VND
                        </p>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default DetailPage;
