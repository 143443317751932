import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onLogin, onLogout } from '../store';
import { addUser } from '../store';
import { Link } from 'react-router-dom';

function Header(props) {
	const [active, setActive] = useState('Home');
	const [userArr, setUserArr] = useState(() => {
    return JSON.parse(localStorage.getItem("userArr")) || [];
  });

  useEffect(() => {
    localStorage.setItem("userArr", JSON.stringify(userArr));
  }, [userArr])
	const dispatch = useDispatch();

	// Check whether user logged
	if (localStorage.getItem("user_email")) {
		dispatch(onLogin(localStorage.getItem("user_email")));
	} 
	else {
		// if not, set a temp user for cart working
		localStorage.setItem('user_email_temp', 'temp@temp.com');
		dispatch(addUser(localStorage.getItem('user_email_temp')));
	}
	// Get user_email if logged in
	var user_email = useSelector((state) => state.user.user_email);

	// Get user_email_temp if not logged in
	var user_email_temp = useSelector((state) => state.cart.user_email_temp);

	console.log(user_email);
	console.log(user_email_temp);

	const [loginUser, setLoginUser] = useState(false);
	const [nameUser, setNameUser] = useState(false);

	const [fullname, setFullname] = useState("")

	useEffect(() => {
		if (!user_email) {
			setLoginUser(false);
			setNameUser(false);
		} else {
			setLoginUser(true);
			setNameUser(true);
		}
	}, [user_email]);

	const activeHandler = (e) => {
		setActive(e);
		console.log(e);
	};

	useEffect(() => {
		if(nameUser) {
		setFullname(userArr.find(item => item.email === user_email).fullname);
		}
	},[nameUser])

	return (
		<div className='container px-0 px-lg-3'>
			<nav className='navbar navbar-expand-lg navbar-light py-3 px-lg-0'>
				<Link className='navbar-brand' to={`/`}>
					<span className='font-weight-bold text-uppercase text-dark'>
						Boutique
					</span>
				</Link>
				<button
					className='navbar-toggler'
					type='button'
					data-toggle='collapse'
					data-target='#navbarNavAltMarkup'
					aria-controls='navbarNavAltMarkup'
					aria-expanded='false'
					aria-label='Toggle navigation'>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
					<ul className='navbar-nav mr-auto'>
						<li
							className='nav-item'
							onClick={() => activeHandler('Home')}>
							<Link
								className='nav-link'
								to={`/`}
								style={
									active === 'Home'
										? { color: '#dcb14a' }
										: { color: 'black' }
								}>
								Home
							</Link>
						</li>
						<li
							className='nav-item'
							onClick={() => activeHandler('Shop')}>
							<Link
								className='nav-link'
								to={`/shop`}
								style={
									active === 'Shop'
										? { color: '#dcb14a' }
										: { color: 'black' }
								}>
								Shop
							</Link>
						</li>
					</ul>
					<ul className='navbar-nav ml-auto'>
						<li className='nav-item'>
							<Link className='nav-link' to={`/cart`}>
								<i className='fas fa-dolly-flatbed mr-1 text-gray'></i>
								Cart
							</Link>
						</li>

						{/* Display name of user */}
						{nameUser && fullname &&
						<>
						<li className='nav-item dropdown'>
							<a
								className='nav-link dropdown-toggle'
								style={{ cursor: 'pointer' }}
								id='pagesDropdown'
								data-toggle='dropdown'
								aria-haspopup='true'
								aria-expanded='false'>
								<i className='fas fa-user-alt mr-1 text-gray'></i>
								{fullname}
							</a>
						<div className='dropdown-menu mt-3' aria-labelledby='pagesDropdown'>
							<Link
							className='dropdown-item border-0 transition-link'
							to={'/history'}>
							History
							</Link>
						</div>
					</li>
					</>}

					{/* Display login or logout button */}
						{loginUser ? <>
						<li className='nav-item' onClick={() => {
							localStorage.removeItem("user_email");
							dispatch(onLogout());
						}}>
							<Link className='nav-link' to='/login'>
							( Logout )
							</Link>
						</li>
						</> : <>
						<li className='nav-item'>
							<Link className='nav-link' to={`/login`}>
							<i className='fas fa-user-alt mr-1 text-gray'></i>Login
							</Link>
						</li>
						</>}
					</ul>
				</div>
			</nav>
		</div>
	);
}

export default Header;
