import { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import Category from "./Component/Category";

function ShopPage() {
  // Fetch data from API
  const apiUrl =
    "https://firebasestorage.googleapis.com/v0/b/funix-subtitle.appspot.com/o/Boutique_products.json?alt=media&token=dc67a5ea-e3e0-479e-9eaf-5e01bcd09c74";

  const { data: products, fetchApi } = useHttp({
    url: apiUrl,
  });

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);
  console.log(products);

  return (
    <>
      <div className="container">
        <section className="py-5 bg-light">
          <div className="container">
            <div className="row px-4 px-lg-5 py-lg-4 align-items-center">
              <div className="col-lg-6">
                <h1 className="h2 text-uppercase mb-0">Shop</h1>
              </div>
            </div>
          </div>
        </section>
        {/* Category */}
        <Category products={products} />
      </div>
    </>
  );
}

export default ShopPage;
