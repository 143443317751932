import { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import convertMoney from "../../convertMoney";

import "./Checkout.css";

function CheckoutPage() {
  const listCart = useSelector((state) => state.cart.listCart);

  const [carts, setCarts] = useState([]);

  const [total, setTotal] = useState(0);

  const [success, setSuccess] = useState(false);

  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    address: "",
  });

  useEffect(() => {
    if (localStorage.getItem("user_email")) {
      const userCart = listCart.filter(
        (item) => item.user === localStorage.getItem("user_email")
      );
      setCarts(userCart);
      getTotal(userCart);
      console.log(userCart);
    }
  }, []);

  function getTotal(carts) {
    let sub_total = 0;
    const sum_total = carts.map((value) => {
      return (sub_total +=
        parseInt(value.priceProduct) * parseInt(value.count));
    });
    setTotal(sub_total);
  }

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //Check email
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const submitHandler = (e) => {
    e.preventDefault();

    //Validate
    if (
      !formData.fullname ||
      !formData.email ||
      !formData.phone ||
      !formData.address
    ) {
      setError("Please full Input");
      setSuccess(false);
    } else if (!validateEmail(formData.email)) {
      setError("Please check your email");
      setSuccess(false);
    } else {
      setError(false);
      setSuccess(true);
    }
  };

  return (
    <div>
      <div className="container">
        <section className="py-5 bg-light">
          <div className="container">
            <div className="row px-4 px-lg-5 py-lg-4 align-items-center">
              <div className="col-lg-6">
                <h1 className="h2 text-uppercase mb-0">Checkout</h1>
              </div>
              <div className="col-lg-6 text-lg-right">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-lg-end mb-0 px-0">
                    <li className="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="cart.html">Cart</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Checkout
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        {!success && (
          <section className="py-5">
            <h2 className="h5 text-uppercase mb-4">Billing details</h2>
            <div className="row">
              <div className="col-lg-8">
                <form>
                  <div className="row">
                    {error && (
                      <span className="text-danger">* {error} ! *</span>
                    )}
                    <div className="col-lg-12 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="Fullname"
                      >
                        Full Name:
                      </label>
                      <input
                        className="form-control form-control-lg"
                        defaultValue={formData.fullname}
                        id="fullname"
                        name="fullname"
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Your Full Name Here!"
                      />
                    </div>
                    <div className="col-lg-12 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="Email"
                      >
                        Email:{" "}
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="email"
                        name="email"
                        defaultValue={formData.email}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Your Email Here!"
                      />
                    </div>
                    <div className="col-lg-12 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="Phone"
                      >
                        Phone Number:{" "}
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="phone"
                        name="phone"
                        defaultValue={formData.phone}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Your Phone Number Here!"
                      />
                    </div>
                    <div className="col-lg-12 form-group">
                      <label
                        className="text-small text-uppercase"
                        htmlFor="Address"
                      >
                        Address:{" "}
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="address"
                        name="address"
                        defaultValue={formData.address}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Your Address Here!"
                      />
                    </div>
                    <div className="col-lg-12 form-group">
                      <a
                        className="btn btn-dark"
                        style={{ color: "white" }}
                        type="submit"
                        onClick={submitHandler}
                      >
                        Place order
                      </a>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-4">
                <div className="card border-0 rounded-0 p-lg-4 bg-light">
                  <div className="card-body">
                    <h5 className="text-uppercase mb-4">Your order</h5>
                    <ul className="list-unstyled mb-0">
                      {carts &&
                        carts.map((value) => (
                          <div key={value.idProduct}>
                            <li className="d-flex align-items-center justify-content-between">
                              <strong className="small font-weight-bold">
                                {value.nameProduct}
                              </strong>
                              <br></br>
                              <span className="text-muted small">
                                {convertMoney(value.priceProduct)} VND x{" "}
                                {value.count}
                              </span>
                            </li>
                            <li className="border-bottom my-2"></li>
                          </div>
                        ))}
                      <li className="d-flex align-items-center justify-content-between">
                        <strong className="text-uppercase small font-weight-bold">
                          Total
                        </strong>
                        <span>{convertMoney(total)} VND</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {success && (
          <section className="py-5">
            <div className="p-5">
              <h1>You Have Successfully Ordered!</h1>
              <p style={{ fontSize: "1.2rem" }}>Please Check Your Email.</p>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

export default CheckoutPage;
