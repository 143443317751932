import { configureStore, createSlice } from "@reduxjs/toolkit";

// Popup Slice
const popupSlice = createSlice({
  name: "popup",
  initialState: {
    product: null,
  },
  reducers: {
    showPopup: (state, action) => {
      state.product = action.payload;
      console.log(state.product);
    },
    hidePopup: (state) => {
      state.product = null;
    },
  },
});

// User Slice
const userSlice = createSlice({
  name: "user",
  initialState: {
    user_email: null,
  },
  reducers: {
    onLogin: (state, action) => {
      state.user_email = action.payload;
      console.log("user email: ", state.user_email);
    },
    onLogout: (state) => {
      state.user_email = null;
      console.log("user email: ", state.user_email);
    },
  },
});

// Cart Slice
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    user_email: "",
    listCart: JSON.parse(localStorage.getItem("listCart")) || [],
  },
  reducers: {
    addUser: (state, action) => {
      state.user_email = action.payload;
    },
    addCart: (state, action) => {
      const data_add_cart = action.payload;
      const add_cart = state.listCart;

      if (add_cart.length < 1) {
        add_cart.push(data_add_cart);
      } else {
        const indexCart = add_cart.findIndex((value) => {
          return value.idProduct === data_add_cart.idProduct;
        });

        const findCart = add_cart.find((value) => {
          return value.idProduct === data_add_cart.idProduct;
        });

        if (!findCart) {
          add_cart.push(data_add_cart);
        } else {
          add_cart[indexCart].count =
            parseInt(add_cart[indexCart].count) + parseInt(data_add_cart.count);
        }
      }

      localStorage.setItem("listCart", JSON.stringify(add_cart));
    },
    deleteCart: (state, action) => {
      const data_delete_cart = action.payload;
      const delete_cart = state.listCart;
      const indexDelete = delete_cart.findIndex((value) => {
        return value.idProduct === data_delete_cart.idProduct;
      });

      delete_cart.splice(indexDelete, 1);
      localStorage.setItem("listCart", JSON.stringify(delete_cart));
    },
    deleteAllCart: (state, action) => {
      state.listCart = action.payload;
      localStorage.setItem("listCart", JSON.stringify(state.listCart));
    },
    updateCart: (state, action) => {
      const data_update_cart = action.payload;
      const update_cart = state.listCart;
      const index = update_cart.findIndex((value) => {
        return value.idProduct === data_update_cart.idProduct;
      });

      update_cart[index].count = data_update_cart.count;
      localStorage.setItem("listCart", JSON.stringify(update_cart));
    },
  },
});

export const store = configureStore({
  reducer: {
    popup: popupSlice.reducer,
    user: userSlice.reducer,
    cart: cartSlice.reducer,
  },
});

export const { showPopup, hidePopup } = popupSlice.actions;
export const { onLogin, onLogout } = userSlice.actions;
export const { addUser, addCart, deleteCart, deleteAllCart, updateCart } =
  cartSlice.actions;
