import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import convertMoney from "../../convertMoney";
import CartDetail from "./CartDetail";
import { deleteCart, updateCart } from "../../store";

function CartPage() {
  const listCart = useSelector((state) => state.cart.listCart);

  const [cart, setCart] = useState([]);

  const [total, setTotal] = useState();

  const dispatch = useDispatch();

  const [success, setSuccess] = useState(false);

  const [dataLoad, setDataLoad] = useState({
    idProduct: "",
    count: "",
  });

  const getTotal = (carts) => {
    let sub_total = 0;

    if (carts) {
      const sum_total = carts.map((value) => {
        return (sub_total +=
          parseInt(value.priceProduct) * parseInt(value.count));
      });
    }
    setTotal(sub_total);
  };

  // Load cart data from localStorage Redux Toolkit
  const getData = () => {
    if (!localStorage.getItem("user_email")) {
      setCart(listCart);
      getTotal(listCart);
    } else {
      const userCart = listCart.filter(
        (item) => item.user === localStorage.getItem("user_email")
      );
      setCart(userCart);
      getTotal(userCart);
      console.log(userCart);
    }
  };

  console.log(cart, total);

  useEffect(() => {
    getData();
  }, [dataLoad]);

  // Update Cart
  const onUpdateCount = (getUserEmail, getIdProduct, updateCount) => {
    console.log(getUserEmail, getIdProduct, updateCount);
    const data = {
      user: getUserEmail,
      idProduct: getIdProduct,
      count: updateCount,
    };

    // Update to redux toolkit
    dispatch(updateCart(data));

    setDataLoad({
      idProduct: getIdProduct,
      count: updateCount,
    });
  };

  // Delete 1 product in cart
  const onDeleteCart = (getUser, getProduct) => {
    const data = {
      idProduct: getProduct,
      user: getUser,
    };

    // Update to redux toolkit
    dispatch(deleteCart(data));

    setDataLoad({
      idProduct: getProduct,
      count: "",
    });
  };

  // Make checkout
  const onCheckout = () => {
    if (!localStorage.getItem("user_email")) {
      alert("Please login");
      return;
    }

    if (!cart) {
      alert("Please check cart");
      return;
    }

    setSuccess(true);
  };

  return (
    <div className="container">
      <section className="py-5 bg-light">
        <div className="container">
          <div className="row px-4 px-lg-5 py-lg-4 align-items-center">
            <div className="col-lg-6">
              <h1 className="h2 text-uppercase mb-0">Cart</h1>
            </div>
            <div className="col-lg-6 text-lg-right"></div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <h2 className="h5 text-uppercase mb-4">Shopping cart</h2>
        <div className="row">
          <div className="col-lg-8 mb-4 mb-lg-0">
            <CartDetail
              listCart={cart}
              onDeleteCart={onDeleteCart}
              onUpdateCount={onUpdateCount}
            />

            <div className="bg-light px-4 py-3">
              <div className="row align-items-center text-center">
                <div className="col-md-6 mb-3 mb-md-0 text-md-left">
                  <Link
                    className="btn btn-link p-0 text-dark btn-sm"
                    to={`/shop`}
                  >
                    <i className="fas fa-long-arrow-alt-left mr-2"> </i>
                    Continue shopping
                  </Link>
                </div>
                <div className="col-md-6 text-md-right">
                  {success && <Navigate to={"/checkout"} />}
                  <span
                    className="btn btn-outline-dark btn-sm"
                    onClick={onCheckout}
                  >
                    Proceed to checkout
                    <i className="fas fa-long-arrow-alt-right ml-2"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card border-0 rounded-0 p-lg-4 bg-light">
              <div className="card-body">
                <h5 className="text-uppercase mb-4">Cart total</h5>
                <ul className="list-unstyled mb-0">
                  <li className="d-flex align-items-center justify-content-between">
                    <strong className="text-uppercase small font-weight-bold">
                      Subtotal
                    </strong>
                    <span className="text-muted small">
                      {convertMoney(total)} VND
                    </span>
                  </li>
                  <li className="border-bottom my-2"></li>
                  <li className="d-flex align-items-center justify-content-between mb-4">
                    <strong className="text-uppercase small font-weight-bold">
                      Total
                    </strong>
                    <span>{convertMoney(total)} VND</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CartPage;
