import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "black",
      }}
    >
      <div className="container text-white py-3">
        <div
          className="row p-4"
          id="content mx-auto"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-4">
            <h5>CUSTOMER SERVICES</h5>
            <ul className="list-unstyled">
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  Help & Contact Us
                </Link>
              </li>
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  Returns & Refunds
                </Link>
              </li>
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  Online Stores
                </Link>
              </li>
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-4">
            <h5>COMPANY</h5>
            <ul className="list-unstyled">
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  Home
                </Link>
              </li>
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  About
                </Link>
              </li>
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  Services
                </Link>
              </li>
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-4">
            <h5>SOCIAL MEDIA</h5>
            <ul className="list-unstyled">
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  Home
                </Link>
              </li>
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  About
                </Link>
              </li>
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  Services
                </Link>
              </li>
              <li>
                <Link href="#" className="text-secondary text-decoration-none">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
