import { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { Link } from "react-router-dom";
import convertMoney from "../../convertMoney";
import { useDispatch } from "react-redux";
import { showPopup } from "../../store";
import { Popup } from "../../components/Popup";

// Import image from img folder
import BannerImg from "../../img/banner1.jpg";
import product1 from "../../img/product_1.png";
import product2 from "../../img/product_2.png";
import product3 from "../../img/product_3.png";
import product4 from "../../img/product_4.png";
import product5 from "../../img/product_5.png";

function HomePage() {
  const dispatch = useDispatch();

  // Fetch data from API
  const apiUrl =
    "https://firebasestorage.googleapis.com/v0/b/funix-subtitle.appspot.com/o/Boutique_products.json?alt=media&token=dc67a5ea-e3e0-479e-9eaf-5e01bcd09c74";

  const { data: product, fetchApi } = useHttp({
    url: apiUrl,
  });

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  return (
    <div className="page-holder">
      <header className="header bg-white">
        <Popup />
        <div className="container">
          {/* Banner section */}
          <section
            className="hero pb-3 bg-cover bg-center d-flex align-items-center"
            style={{ backgroundImage: `url(${BannerImg})` }}
          >
            <div className="container py-5">
              <div className="row px-4 px-lg-5">
                <div className="col-lg-6">
                  <p className="text-muted small text-uppercase mb-2">
                    New Inspiration 2020
                  </p>
                  <h1 className="h2 text-uppercase mb-3">
                    20% off on new season
                  </h1>
                  <a className="btn btn-dark" href="./shop">
                    Browse collections
                  </a>
                </div>
              </div>
            </div>
          </section>

          {/* Category section */}
          <section className="pt-5">
            <header className="text-center">
              <p className="small text-muted small text-uppercase mb-1">
                Carefully created collections
              </p>
              <h2 className="h5 text-uppercase mb-4">Browse our categories</h2>
            </header>

            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="row">
                  <div className="col-md-6 mb-4 mb-md-0">
                    <Link
                      className="category-item"
                      to={"/shop?category=iphone"}
                    >
                      <img className="img-fluid" src={product1} alt="" />
                    </Link>
                  </div>

                  <div className="col-md-6 mb-4 mb-md-0">
                    <Link className="category-item" to={"/shop?category=mac"}>
                      <img className="img-fluid" src={product2} alt="" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4 mb-4 mb-md-0">
                    <Link className="category-item" to={"/shop?category=ipad"}>
                      <img className="img-fluid" src={product3} alt="" />
                    </Link>
                  </div>

                  <div className="col-md-4 mb-4 mb-md-0">
                    <Link className="category-item" to={"/shop?category=watch"}>
                      <img className="img-fluid" src={product4} alt="" />
                    </Link>
                  </div>

                  <div className="col-md-4 mb-4 mb-md-0">
                    <Link
                      className="category-item"
                      to={"/shop?category=airpod"}
                    >
                      <img className="img-fluid" src={product5} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Product section */}
          <section className="py-5" id="section_product">
            <header>
              <p className="small text-muted small text-uppercase mb-1">
                Made the hard way
              </p>
              <h2 className="h5 text-uppercase mb-4">Top trending products</h2>
            </header>
            <div className="row">
              {product &&
                product.map((item) => (
                  <div
                    className="col-xl-3 col-lg-4 col-sm-6"
                    key={item._id.$oid}
                  >
                    <div className="product text-center">
                      <div className="position-relative mb-3">
                        <div className="badge text-white badge-"></div>
                        <a
                          className="d-block"
                          href={`#product_${item._id.$oid}`}
                          onClick={() => dispatch(showPopup(item))}
                          data-toggle="modal"
                        >
                          <img className="img-fluid" src={item.img1} alt="" />
                        </a>
                        <div className="product-overlay">
                          <ul className="mb-0 list-inline"></ul>
                        </div>
                      </div>
                      <h6>
                        <Link
                          className="reset-anchor"
                          to={`/detail/${item._id.$oid}`}
                        >
                          {item.name}
                        </Link>
                      </h6>
                      <p className="small text-muted">
                        {convertMoney(item.price)} VND
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </section>

          {/* Bottom of Page */}
          <section className="py-5 bg-light">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg-4 mb-3 mb-lg-0">
                  <div className="d-inline-block">
                    <div className="media align-items-end">
                      <svg className="svg-icon svg-icon-big svg-icon-light">
                        <use xlinkHref="#delivery-time-1"></use>
                      </svg>
                      <div className="media-body text-left ml-3">
                        <h6 className="text-uppercase mb-1">Free shipping</h6>
                        <p className="text-small mb-0 text-muted">
                          Free shipping worlwide
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 mb-3 mb-lg-0">
                  <div className="d-inline-block">
                    <div className="media align-items-end">
                      <svg className="svg-icon svg-icon-big svg-icon-light">
                        <use xlinkHref="#helpline-24h-1"> </use>
                      </svg>
                      <div className="media-body text-left ml-3">
                        <h6 className="text-uppercase mb-1">24 x 7 service</h6>
                        <p className="text-small mb-0 text-muted">
                          Free shipping worlwide
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="d-inline-block">
                    <div className="media align-items-end">
                      <svg className="svg-icon svg-icon-big svg-icon-light">
                        <use xlinkHref="#label-tag-1"> </use>
                      </svg>
                      <div className="media-body text-left ml-3">
                        <h6 className="text-uppercase mb-1">Festival offer</h6>
                        <p className="text-small mb-0 text-muted">
                          Free shipping worlwide
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Email Subscribe */}
          <section className="py-5">
            <div className="container p-0">
              <div className="row">
                <div className="col-lg-6 mb-3 mb-lg-0">
                  <h5 className="text-uppercase">Let's be friends!</h5>
                </div>
                <div className="col-lg-6">
                  <form action="#">
                    <div className="input-group flex-column flex-sm-row mb-3">
                      <input
                        className="form-control form-control-lg py-3"
                        type="email"
                        placeholder="Enter your email address"
                        aria-describedby="button-addon2"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-dark btn-block"
                          id="button-addon2"
                          type="submit"
                        >
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </header>
    </div>
  );
}

export default HomePage;
